<template>
    <v-dialog v-model="dialog" max-width="800px" persistent overlay-opacity="0.75">
      	<v-card class="elevation-0 grey darken-3">
			<v-toolbar color="blue-grey darken-3" dark dense elevation="0">
				<v-toolbar-title>{{ item.title + '(' + item.column_name + ')' }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn disabled plain small></v-btn>
			</v-toolbar>
	        <v-card-text>
	        </v-card-text>
          		<v-container v-if="!loading">
					<v-row>
						<v-col>
                            <v-textarea
                                v-model="optionsText"
                                label="Select Options"
                                outlined
                                rows="15"
                                row-height="20"
                                @input="hasChanged($event)"
                            ></v-textarea>
						</v-col>
					</v-row>
	        	</v-container>
	        <v-card-text>
	        </v-card-text>
        	<v-card-actions>
        		<v-btn text class="warning mx-0 mb-2" @click="onCancel()">Abbrechen</v-btn>
        		<v-btn v-if="!error" text class="success mx-1 mb-2" :loading="loading" :disabled="loading || !dirty"
				 @click="onSubmit()">Speichern</v-btn>
        		<v-btn v-else text class="error mx-1 mb-2" disabled>{{ error }}</v-btn>
			    <v-spacer />
        	</v-card-actions>
      	</v-card>
		<v-dialog v-model="showmessage">
			<v-toolbar dark color="warning" dense>
				<v-toolbar-title class="white--text">Notification</v-toolbar-title>
			</v-toolbar>
			<v-card tile>
				<v-card-text color="red" v-html="message"/>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="blue" text @click="onMessageClose()">Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
    </v-dialog>          
</template>
<script>

export default {
  name: "SelectOptions",
  components: {},
	  	props: {
			item: {
				type: Object, default: {}, required: true,
			},
	  	},
		data: () => ({
			dialog: false,
			loading: false,
            options: [],
            optionsText: '',
			dirty: false,
			error: '',
			message: '',
			showmessage: false,
		}),
		computed: {
		},
		methods: {
			closeDialog(event, param = null) {
    			this.loading = false
				this.dialog = false
	      		this.$emit(event, param)
			},
			msgError(errorMsg) {
    			this.loading = false
    			this.error = "Eingaben konnten nicht gespeichert werden"
				let self = this
		        setTimeout(() => {
		        	self.error = ''
		        }, 5000);
		        this.message = '<code>' + JSON.stringify(errorMsg).substring(0, 200) + '</code>'
		        this.showmessage = true
			},
			onSubmit() {
                this.options = this.optionsText.split("\n")
                let opt = JSON.stringify(this.options.filter(e => e != ''))
                console.log('options:', opt)
                let optionsItem = {
                    'id': this.item.id,
                    'opt': opt
                }
                this.closeDialog('submitted', optionsItem)
			},
			onCancel() {
                this.closeDialog('cancelled')
			},
			onMessageClose() {
		        this.message = ''
		        this.showmessage = false			
			},
            hasChanged() {
                this.dirty = true
            },
		},
		mounted() {
            if(this.item.opt) {
                this.options = JSON.parse(this.item.opt)
                this.optionsText = this.options.join('\n')
            } else {
                this.optionsText = ''
            }
			this.dialog = true
		},
		watch: {
		},
	}  
</script>

<style>
.theme--dark.v-label {
    color: rgba(255, 255, 255, 0.3);
}
</style>
